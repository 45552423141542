
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  //查询-条件查询客户对账单汇总信息
  findCustomerReport(data){
    return request({
      method:'POST',
      url:'/report/customer/findCustomerReport',
      data
    })
  },
  // 查询-条件查询客户对账单统计列表信息
  findCustomerReportList(data){
    return request({
      method:'POST',
      url:'/report/customer/findCustomerReportList',
      data
    })
  },
  // 查询-条件查询客户对账单客户详情统计列表信息
  findCustomerCustomerDetailsList(data){
    return request({
      method:'POST',
      url:'/report/customer/findCustomerCustomerDetailsList',
      data
    })
  },
  // 查询-条件查询客户对账单员工统计列表信息
  findCustomerUserDetailsList(data){
    return request({
      method:'POST',
      url:'/report/customer/findCustomerUserDetailsList',
      data
    })
  },
}