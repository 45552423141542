<!--客户对帐单-->
<template>
  <div class="statement_box">
    <div 
      v-loading="dateLoading" 
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="statement_top_date">
      <div class="statement_date_query">
        <div class="statement_date_query_left">
            <!--年-->
          <el-select v-model="yearValue" placeholder="年" @change="handleDateChange('year')">
            <el-option
              v-for="item in yearOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--季度-->
          <el-select v-model="quarterValue" placeholder="季" @change="handleDateChange('quarter')">
            <el-option
              v-for="item in quarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--月-->
          <el-select v-model="monthValue" placeholder="月" @change="handleDateChange('month')">
            <el-option
              v-for="item in monthOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--天-->
          <el-select v-model="dayValue" placeholder="日" @change="handleDateChange('day')">
            <el-option
              v-for="item in dayOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" :plain="isAll" @click="handleSearchBtn">全部</el-button>
          <el-date-picker
            class="date_range"
            v-model="pickerValue"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="onDatePickerChange">
          </el-date-picker>
          <el-radio v-model="staffValue" :label="0" @change="handleCheckBtn">按客户</el-radio>
          <el-radio v-model="staffValue" :label="1" @change="handleCheckBtn">按员工</el-radio>
        </div>
        <div class="statement_date_query_right">
          <div class="statement_explain">统计说明<i class="el-icon-question"></i></div>
          <el-button plain size="small" @click="exportStatement">导出</el-button>
        </div>
      </div>
      <div class="statement_date_content">
        <div class="statement_date_content_row statement_interval">
          <div class="statement_date_content_col">
            <div class="statement_date_label">应收欠款</div>
            <div class="statement_date_val">{{divisionMoney(collectData.arrears)}}</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_val">=</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_label">期初应收</div>
            <div class="statement_date_val">{{divisionMoney(collectData.waitGathering)}}</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_val">+</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_label">销售金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.practicalAmount)}}</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_val">+</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_label">税额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.taxAmount)}}</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_val">+</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_label">其他费用</div>
            <div class="statement_date_val">{{divisionMoney(collectData.costOther)}}</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_val">-</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_label">已收金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.alreadyAmount)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row statement_auto">
          <div class="statement_date_content_col">
            <div class="statement_date_label">逾期应收</div>
            <div class="statement_date_val">{{divisionMoney(collectData.overdueAmount)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="statement_chart_box">
      <div class="statement_chart_query">
        <el-select v-model="chartValue" placeholder="请选择" @change="handleChartChange">
          <el-option
            v-for="item in chartOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="statement_chart_content" id="achievements_chart"></div>
    </div>
    <div v-show="(staffValue === 0 && !sonParams)" class="statement_table_box">
      <div class="statement_table_title">按客户</div>
      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="customerName"
          label="客户名称">
          <template slot-scope="scope">
            <span class="company_color" @click="handleSonData(scope.row)">{{scope.row.customerName || "暂无客户名"}}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="arrears"
          label="应收欠款">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="waitGathering"
          label="期初应收">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="practicalAmount"
          label="实际交易">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="taxAmount"
          label="税额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costOther"
          label="其它费用">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="alreadyAmount"
          label="已收款">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="overdueAmount"
          label="逾期应收">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationOptions.page"
        :page-size="paginationOptions.size"
        :total="paginationOptions.total"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <div v-show="(staffValue === 1 && !sonParams)" class="statement_table_box">
      <div class="statement_table_title">按人员</div>
      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="userName"
          label="员工姓名">
          <template slot-scope="scope">
            <span class="company_color" @click="handleSonData(scope.row)">{{scope.row.userName}}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="practicalAmount"
          label="实际交易">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="alreadyAmount"
          label="已收款">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="awaitAmount"
          label="应收款">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="overdueAmount"
          label="逾期应收">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsGrossMargin"
          label="销售毛利">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationOptions.page"
        :page-size="paginationOptions.size"
        :total="paginationOptions.total"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <div v-show="(staffValue === 0 && sonParams)" class="statement_table_box">
      <div class="statement_table_title">{{sonParams.customerName || "暂无客户名"}} 应收款明细 <el-button @click="handleSonData('')" class="table_goback" size="small">返回</el-button></div>
      <el-table
        :data="tableSonData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="billsNo"
          label="订单号">
          <template slot-scope="scope">
            <span class="order_color">{{scope.row.billsNo}}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="countTime"
          label="订单日期">
          <template slot-scope="scope">
            {{getSingleDate(scope.row.countTime)}}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="practicalAmount"
          label="订单金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="alreadyAmount"
          label="已收金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="awaitAmount"
          label="应收金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="overdueAmount"
          label="逾期应收">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="callAmount"
          label="催收">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationSonOptions.page"
        :page-size="paginationSonOptions.size"
        :total="paginationSonOptions.total"
        @current-change="handleSonCurrentChange">
      </el-pagination>
    </div>
    <div v-show="(staffValue === 1 && sonParams)" class="statement_table_box">
      <div class="statement_table_title">{{sonParams.userName}} 应收款明细 <el-button @click="handleSonData('')" class="table_goback" size="small">返回</el-button></div>
      <el-table
        :data="tableSonData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="customerName"
          label="客户名称">
          <template  slot-scope="scope">
            {{ scope.row.customerName || "暂无客户名"}}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsCount"
          label="订单数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="arrears"
          label="应收欠款">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="waitGathering"
          label="期初应收">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="practicalAmount"
          label="实际交易">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="awaitAmount"
          label="应收金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costOther"
          label="其它费用">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="overdueAmount"
          label="逾期应收">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="callAmount"
          label="催收">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationSonOptions.page"
        :page-size="paginationSonOptions.size"
        :total="paginationSonOptions.total"
        @current-change="handleSonCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import clientApi from '@/network/statement/capital/client/index.js';
  import {parseTime} from '@/util/index.js';

  export default{
    data() {
      return{
        dateLoading: false,
        yearOptions: "",
        quarterOptions: "",
        monthOptions: "",
        dayOptions: "",
        yearValue: "",
        quarterValue: "",
        monthValue: "",
        dayValue: "",
        isAll: true,
        pickerValue: "",
        staffValue: 0,
        chartOptions: [{
          label: "应收欠款",
          value: 1
        },{
          label: "实际交易",
          value: 2
        },{
          label: "已收款",
          value: 3
        },{
          label: "逾期应收",
          value: 4
        }],
        chartValue: 1,
        collectData: "",
        chartData: [],
        tableData: [],
        paginationOptions: {
          page: 1,
          size: 10,
          total: 0
        },
        sonParams: '',
        tableSonData: [],
        paginationSonOptions: {
          page: 1,
          size: 10,
          total: 0
        },
      }
    },
    mounted() {
      this.getTimeist()
    },
    methods: {
      // 获取年季月
      getTimeist(){
        let t = new Date();
        let y = t.getFullYear()
        let m = t.getMonth()
        let q = parseInt(m/3) + 1
        // 获取本年本季本月
        this.yearValue = y
        this.quarterValue = q
        this.monthValue = m + 1
        // 设置下拉框展示的年
        let maxY = 10, yArr = []
        // 年
        for(let i = 0; i < maxY; i++){
          yArr.push({
            label: (y - i) + "年",
            value: y - i
          })
        }
        this.yearOptions = yArr
        // 季
        this.getQuarterList()
        // 月
        this.getMonthList(q)
        // 天
        this.getDayList(y, m)
        // 获取数据
        this.getList()
        this.getChartList()
        this.getCollectData()
      },
        // 获取季度
      getQuarterList(){
        // 季
        let qArr = []
        qArr.push({
          label: "全部",
          value: null
        })
        for(let i = 1; i <= 4; i++){
          qArr.push({
            label: "第" + i + "季度",
            value: i
          })
        }
        this.quarterOptions = qArr
      },
      // 根据季度获取月份
      getMonthList(q){
        let minM = q*3 - 2
        let maxM = q*3
        let mArr = []
        for(let i = minM; i <= maxM; i++){
          mArr.push({
            label: i + "月",
            value: i
          })
        }
        this.monthOptions = mArr
      },
      // 获取选中月份的天数
      getDayList(y,m){
        let maxD = new Date(y, m, 0).getDate()
        if(this.dayValue > maxD){
          this.dayValue = 1
        }
        let dArr = []
        for(let i = 1; i <= maxD; i++){
          dArr.push({
            label: i + "号",
            value: i
          })
        }
        this.dayOptions = dArr
      },
      // 监听下拉时间变动
      handleDateChange(type){
        this.isAll = true
        this.pickerValue = ""
        if(type === "year"){
          this.monthOptions = ""
          this.dayOptions = ""
          this.quarterValue = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getQuarterList()
        }else if(type === "quarter"){
          this.dayOptions = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getMonthList(this.quarterValue)
        }else if(type === "month"){
          this.dayValue = ""
          this.getDayList(this.yearValue, this.monthValue)
        }
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 查询全部
      handleSearchBtn(){
        this.pickerValue=""
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 按字段时间范围查询
      onDatePickerChange(){
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = this.pickerValue ? true : false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 选中部门或者员工
      handleCheckBtn(){
        this.chartValue = 1;
        this.sonParams = ""
        if(this.staffValue === 0){
          this.chartOptions = [{
            label: "应收欠款",
            value: 1
          },{
            label: "实际交易",
            value: 2
          },{
            label: "已收款",
            value: 3
          },{
            label: "逾期应收",
            value: 4
          }]
        }else{
          this.chartOptions = [{
            label: "应收款",
            value: 1
          },{
            label: "实际交易",
            value: 2
          },{
            label: "已收款",
            value: 3
          },{
            label: "逾期应收",
            value: 4
          }]
        }
        this.getList()
        this.getChartList()
      },
      // 点击查看下一级表格数据或者返回
      handleSonData(data){
        this.sonParams = data
        if(data) {
          this.paginationSonOptions = {
            page: 1,
            size: 10,
            total: 0
          }
          if(this.staffValue === 0){
            this.getCompanyList()
          }else{
            this.getUserList()
          }
        }
      },
      // 获取客户对账单汇总信息
      getCollectData(){
        const [startTime, endTime] = this.pickerValue
        clientApi.findCustomerReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
        }).then((res)=>{
          if(res.code == 200){
            this.collectData = res.data || ""
          }
        })
      },
      // 获取客户对账单数据
      getList(){
        const [startTime, endTime] = this.pickerValue
        clientApi.findCustomerReportList({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          customerType: this.staffValue,
          pageIndex: this.paginationOptions.page,
          pageSize: this.paginationOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableData = res.data.rows
            this.paginationOptions = {...this.paginationOptions,total:res.data.total}
          }
        })
      },
      // 获取图表数据
      getChartList(){
        const [startTime, endTime] = this.pickerValue
        clientApi.findCustomerReportList({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          customerType: this.staffValue,
          pageIndex: 1,
          pageSize: 20,
        }).then((res)=>{
          if(res.code == 200){
            this.chartData = res.data.rows
            this.graphing()
          }
        })
      },
      // 获取客户应收款数据
      getCompanyList(){
        const [startTime, endTime] = this.pickerValue
        clientApi.findCustomerCustomerDetailsList({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          customerId: this.sonParams.customerId,
          pageIndex: this.paginationSonOptions.page,
          pageSize: this.paginationSonOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableSonData = res.data.rows
            this.paginationSonOptions = {...this.paginationSonOptions,total:res.data.total}
          }
        })
      },
      // 获取员工应收款数据
      getUserList(){
        const [startTime, endTime] = this.pickerValue
        clientApi.findCustomerUserDetailsList({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          userId: this.sonParams.userId,
          pageIndex: this.paginationSonOptions.page,
          pageSize: this.paginationSonOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableSonData = res.data.rows
            this.paginationSonOptions = {...this.paginationCompanyOptions,total:res.data.total}
          }
        })
      },
      // 表格数据
      _formatJson(filterVal, jsonData){
        let data = jsonData.map(row => filterVal.map(j => {
          let value = ''
          value = row[j]
          return value
        }))
        return data
      },
      // 导出
      exportStatement(){
        const [startTime, endTime] = this.pickerValue
        clientApi.findCustomerReportList({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          customerType: this.staffValue,
          pageIndex: 1,
          pageSize: 10000,
        }).then((res)=>{
          if(res.code == 200){
            import('@/util/Export2Excel').then(excel => {
              let excelObj = {
                customerName: "客户名称",
                arrears: "应收欠款",
                waitGathering: "期初应收",
                practicalAmount: "实际交易",
                taxAmount: "税额",
                costOther: "其它费用",
                alreadyAmount: "已收款",
                overdueAmount: "税逾期应收额",
              }
              if(this.staffValue === 1){
                excelObj = {
                  userName: "员工姓名",
                  practicalAmount: "实际交易",
                  alreadyAmount: "已收款",
                  awaitAmount: "应收款",
                  overdueAmount: "逾期应收",
                  billsGrossMargin: "销售毛利",
                }
              }
              const rows = res.data.rows
              const data = this._formatJson(Object.keys(excelObj), rows)
              excel.export_json_to_excel({
                header: Object.values(excelObj),
                data,
                filename: '客户对帐单',
                autoWidth: true,
                bookType: 'xlsx',
              })
            })
          }
        })
      },
      // 金额展示添加逗号处理
      divisionMoney(val){
        let str = (val + '').split('.')
        let intSum = str[0].replace(/\B(?=(?:\d{3})+$)/g, ',')
        let dot = str[1] ? '.' + str[1] : ''
        let num = intSum + dot
        return num
      },
      // 查询图标数据
      handleChartChange(){
        this.graphing()
      },
      // 获取y轴名称
      getYName(){
        if(this.staffValue === 0){
          switch (this.chartValue) {
            case 1:
              return '应收欠款'
            case 2:
              return '实际交易'
            case 3:
              return '已收款'
            default:
              return '逾期应收'
          }
        }else{
          switch (this.chartValue) {
            case 1:
              return '应收款'
            case 2:
              return '实际交易'
            case 3:
              return '已收款'
            default:
              return '逾期应收'
          }
        }
      },
      // 获取x轴或者y轴数组
      getXName(x){
        let axisArr = []
        if(this.staffValue === 0){
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.customerName || "暂无客户名")
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(this.chartValue === 1 ? item.arrears : this.chartValue === 2 ? item.practicalAmount : this.chartValue === 3 ? item.alreadyAmount : item.overdueAmount)
            })
          }
        }else{
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.userName)
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(this.chartValue === 1 ? item.awaitAmount : this.chartValue === 2 ? item.practicalAmount : this.chartValue === 3 ? item.alreadyAmount : item.overdueAmount)
            })
          }
        }
        return axisArr
      }, 
      // 生成图表
      graphing(){
        let yName = this.getYName()
        let xAxisData = this.getXName(1)
        let yAxisData = this.getXName()
        let myChart = this.$echarts.init(document.getElementById('achievements_chart'))
         let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: 40,
            right: 10,
            bottom: 0,
            containLabel: true
          },
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true,
                show: false
              },
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc',
                }
              },
              splitLine: {
                show: true,
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              boundaryGap: false,
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc'
                }
              },
              splitLine: {
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          color: ['#0091ff'],
          series: [
            {
              name: yName,
              type: 'line',
              data: yAxisData,
              animation:true,
              areaStyle: {}
            }
          ]
        }
        myChart.setOption(option);
      },
      // 点击页数
      handleCurrentChange(e){
        this.paginationOptions = {...this.paginationOptions, page: e}
        this.getList()
      },
      // 点击页数
      handleSonCurrentChange(e){
        this.paginationSonOptions = {...this.paginationSonOptions, page: e}
        if(this.staffValue === 0){
          this.getCompanyList()
        }else{
          this.getUserList()
        }
      },
      //获取年月日
      getSingleDate(str) {
        return parseTime(str, '{y}-{m}-{d}')
      }
    }
  }
</script>

<style lang="less">
.statement_date_content_col{
  flex: 1;
}
.company_color{
  color: #409EFF;
  cursor: pointer;
}
.order_color{
  color: #409EFF;
}
.table_goback{
  margin-left: 10px;
}
</style>
